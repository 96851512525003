import React from "react";
import { Route, Switch, withRouter, Link } from "react-router-dom";
import { Iletisim, AnaSayfa, AramaSonuclari, SayfaBulunamadi } from "./sayfalar";
import { Navbar, Nav, NavDropdown, Form, FormControl, Container, Offcanvas, Button, Col, Row } from "react-bootstrap";
import "./index.css";
import FloatingActionButton from "./API/fab"
import Enalt from "./API/enalt";
import FaaliyetlerimizMenuOgesi from "./API/faaliyetlerimizmenuogesi";
import Faaliyetlerimiz from "./sayfalar/Faaliyetlerimiz"
import FaaliyetDetay from "./sayfalar/FaaliyetDetay";
import { FaCreditCard, FaSearch } from 'react-icons/fa';
import Projelerimiz from "./sayfalar/Projelerimiz";
import ProjeDetay from "./sayfalar/ProjeDetay";
import KurumsalMenuOgesi from "./API/kurumsalmenuogesi"
import Kurumsal from "./sayfalar/Kurumsal";
import Duyurulular from "./sayfalar/Duyurular";
import DuyuruDetay from "./sayfalar/DuyuruDetay";

import { CSSTransition, TransitionGroup } from "react-transition-group";


class Ana extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      aramagorunumu: false,

      faaliyetlerimiz_isloaded: false,
      faaliyetlerimiz_error: "yok",
      faaliyetlerimiz: [],

      projelerimiz_isloaded: false,
      projelerimiz_error: "yok",
      projelerimiz: [],

      kurumsal_isloaded: false,
      kurumsal_error: "yok",
      kurumsal: [],

      karusel_isloaded: false,
      karusel_error: "yok",
      karusel: [],

      duyurular_isloaded: false,
      duyurular_error: "yok",
      duyurular: [],

    };
    fetch("JSON/faaliyetlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          faaliyetlerimiz_isloaded: true,
          faaliyetlerimiz: result.faaliyetlerimiz
        });
      },
      (error) => {
        this.setState({
          faaliyetlerimiz_isloaded: true,
          faaliyetlerimiz_error: "Hata var " + error
        });
      }
    )

    fetch("JSON/projelerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          projelerimiz_isloaded: true,
          projelerimiz: result.projelerimiz
        });
      },
      (error) => {
        this.setState({
          projelerimiz_isloaded: true,
          projelerimiz_error: "Hata var " + error
        });
      }
    )

    fetch("JSON/kurumsal.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          kurumsal_isloaded: true,
          kurumsal: result.kurumsal
        });
      },
      (error) => {
        this.setState({
          kurumsal_isloaded: true,
          kurumsal_error: "Hata var " + error
        });
      }
    )
    fetch(process.env.PUBLIC_URL + "/JSON/karusel.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          karusel_isloaded: true,
          karusel: result.karusel
        });
      },
      (error) => {
        this.setState({
          karusel_isloaded: true,
          karusel_error: "Hata var " + error
        });
      }
    )

    fetch("JSON/duyurular.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          duyurular_isloaded: true,
          duyurular: result.duyurular
        });
      },
      (error) => {
        this.setState({
          duyurular_isloaded: true,
          duyurular_error: "Hata var " + error
        });
      }
    )

    this.aramaref = React.createRef();
  }

  handleRoute = route => () => {
    this.props.history.push({ pathname: route });
  };

  handleSearchInput = event => {
    this.setState({
      searchText: event.target.value
    });
  };

  handleSearchSubmit = () => {
    if (this.state.searchText.length >= 3) {
      this.props.history.push({
        pathname: "/aramasonuclari",
        state: {
          searchText: this.state.searchText
        }
      });
    } else {
      alert("Please enter some search text!");
    }
  };

  handleShow = () => {
    this.setState({ aramagorunumu: true }, () => {
      this.aramaref.current.focus();
      this.aramaref.current.select();
    });
  };

  handleClose = () => {
    this.setState({ aramagorunumu: false });
  };

  enterSearchSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (this.state.searchText.length >= 3) {
        this.setState({ aramagorunumu: false });
        this.props.history.push({
          pathname: "/aramasonuclari",
          state: {
            searchText: this.state.searchText
          }
        });
      } else {
        alert("Arama yapmak için en az 3 karakter girin");
        this.props.history.push("/");
      }
    }
    else if (e.key === "Escape") {
      e.preventDefault();
      this.handleClose();
    }

  };


  render() {
    const { kurumsal, faaliyetlerimiz, projelerimiz, duyurular } = this.state;
    const { location } = this.props;
    return (
      <>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="custom" className="navbar-light" fixed="top">
          <Container>
            {/*BÜYÜK EKRANDA LOGO*/}
            <Navbar.Brand eventkey="1" as={Link} to="/" className="d-none d-lg-block ">
              <img alt="" src="logo.png" height="30" className="d-inline-block align-top" />{' '}<span className="buyukekranbrand">Minik Dokunuşlar Derneği</span>
            </Navbar.Brand>
            {/*KÜÇÜK EKRANDA LOGO*/}
            <Navbar.Brand className="d-block d-lg-none order-first">
              <Nav.Link eventkey="2" as={Link} to="/projelerimiz" href="#" className="syhlnk">
                <img alt="" src="logo.png" height="30" className="d-inline-block align-top" />{' '}
                <span className="kucukekranbrand syhlink">Minik Dokunuşlar Derneği</span>
              </Nav.Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />

            <Navbar.Collapse id="navbarScroll">
              <Nav className="me-auto">
                <Nav.Link eventkey="15" as={Link} to="/" href="#">Anasayfa</Nav.Link>
                <NavDropdown title="Kurumsal" id="collasible-nav-dropdown">
                  <KurumsalMenuOgesi />
                </NavDropdown>
                <NavDropdown title="Faaliyetlerimiz" id="faaliyetlerimizmenusu">
                  <FaaliyetlerimizMenuOgesi />
                </NavDropdown>

                <Nav.Link eventkey="13" as={Link} to="/projelerimiz" href="#">Projelerimiz</Nav.Link>

                <Nav.Link eventkey="14" as={Link} to="/duyurular" href="#">Duyurular</Nav.Link>

                <Nav.Link eventkey="15" as={Link} to="/iletisim" href="#">İletişim</Nav.Link>
              </Nav>
              <Nav id="sag" className="ml-auto d-none d-xl-block">
                <Nav.Link onClick={this.handleShow}> <FaSearch /> Arama </Nav.Link>
              </Nav>
              <Nav className="ml-auto d-none d-xl-block">
                <Nav.Link as={Link} to="/kurumsal/online-bagis" href="#" > <FaCreditCard /> Online Bağış </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <Offcanvas aria-labelledby="deneme" show={this.state.aramagorunumu} placement="top" >
              <Offcanvas.Header onHide={this.handleClose}>
                <Offcanvas.Title>Arama</Offcanvas.Title>
                <Button variant="outline-dark" onClick={this.handleClose}>X</Button>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Form className="d-flex outline-success me-50">
                  <FormControl
                    autoFocus
                    onChange={this.handleSearchInput}
                    value={this.state.searchText}
                    onKeyPress={(e) => this.enterSearchSubmit(e)}
                    type="text"
                    placeholder="Ara"
                    aria-label="Ara"
                    ref={this.aramaref}
                  />
                </Form>
              </Offcanvas.Body>
            </Offcanvas>
          </Container>
          <Container className="ml-auto d-block d-xl-none">
            <Nav>
              <Row>
                <Col xs={6} className="d-flex justify-content-center">
                  <Nav.Link onClick={this.handleShow}> <FaSearch /> Arama </Nav.Link>
                </Col>
                <Col xs={6} className="d-flex justify-content-center">
                  <Nav.Link as={Link} to="/kurumsal/online-bagis" href="#" > <FaCreditCard /> Online Bağış </Nav.Link>
                </Col>
              </Row>
            </Nav>
          </Container>
        </Navbar>
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={{ enter: 1000, exit: 500 }}
            classNames="fade"

            onExited={node => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant',
              });
            }}
          >
            <section className="route-section">
              <Switch location={location}>
                <Route exact path="/" component={AnaSayfa} />
                <Route exact path="/iletisim" component={Iletisim} />
                <Route exact path="/aramasonuclari" component={AramaSonuclari} />
                <Route exact path="/faaliyetlerimiz" component={Faaliyetlerimiz} />
                <Route exact path="/projelerimiz" component={Projelerimiz} />
                <Route exact path="/duyurular" component={Duyurulular} />
                {faaliyetlerimiz.map((m, i) => {
                  if (!m.gizli)
                    return (<Route key={"ana_faaliyetlerimiz_uzun_1"} exact path={"/faaliyetlerimiz/" + m.id} component={() => (<FaaliyetDetay id={m.id} isim={m.isim} anasayfa_aciklamakisa={m.anasayfa_aciklamakisa} anasayfa_aciklamatam={m.anasayfa_aciklamatam} detay_aciklama={m.detay_aciklama} resimgenis={m.resimgenis} resimdik={m.resimdik} album={m.album} metatitle={m.metatitle} metadescription={m.metadescription} metakeywords={m.metakeywords} />)} />);
                  else
                    return "";
                })}

                {projelerimiz.map((m, i) => {
                  if (!m.gizli)
                    return (<Route key={"ana_projelerimiz_uzun_1"} exact path={"/projelerimiz/" + m.id} component={() => (<ProjeDetay id={m.id} isim={m.isim} resim={m.resim} icerik={m.icerik} album={m.album} metatitle={m.metatitle} metadescription={m.metadescription} metakeywords={m.metakeywords} />)} />);
                  else
                    return "";
                })}

                {kurumsal.map((m, i) => {
                  if (m.tur === "sayfa")
                    return (<Route key={"ana_kurumsal_uzun_1"} exact path={"/kurumsal/" + m.id} component={() => (<Kurumsal id={m.id} baslik={m.baslik} icerik={m.icerik} resim={m.resim} resimvarmi={m.resimvarmi} metatitle={m.metatitle} metadescription={m.metadescription} metakeywords={m.metakeywords} />)} />);
                  else
                    return "";
                })}

                {duyurular.map((m, i) => {
                  if (!m.gizli)
                    return (<Route key={"ana_duyurular_uzun_1"} exact path={"/duyurular/" + m.id} component={() => (<DuyuruDetay id={m.id} baslik={m.baslik} yazar={m.yazar} tarih={m.tarih} icerik={m.icerik} resimvarmi={m.resimvarmi} resim={m.resim} metatitle={m.metatitle} metadescription={m.metadescription} metakeywords={m.metakeywords} />)} />);
                  else
                    return "";
                })}

                <Route component={SayfaBulunamadi} />
              </Switch>
              <Enalt />
            </section>
          </CSSTransition>
        </TransitionGroup>

        <section>
          <FloatingActionButton />
        </section>

      </>
    );
  }
}

export default withRouter(Ana);

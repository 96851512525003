import React from "react";
import FaaliyetAramaOgesi from "../API/aramaogesifaaliyet";
import ProjeAramaOgesi from "../API/aramaogesiproje";
import DuyuruAramaOgesi from "../API/aramaogesiduyuru";
import KurumsalAramaOgesi from "../API/aramaogesikurumsal";

import { Row, Container } from "react-bootstrap"
import Helmet from "react-helmet";

export default class AramaSonuclari extends React.Component {

  constructor(props) {
    super(props);
    this.handleChangeTel = this.handleChangeTel.bind(this);

    this.state = {
      tel: "",
      simdibasvur: false,
      seciliOlan: "",
      isLoading: true,
      searchText: "",
      faaliyetAramaSonuclari: [],
      projeAramaSonuclari: [],
      duyuruAramaSonuclari: [],
      kurumsalAramaSonuclari: [],


      faaliyetlerimiz_isloaded: false,
      faaliyetlerimiz_error: "yok",
      faaliyetlerimiz: [],

      projelerimiz_isloaded: false,
      projelerimiz_error: "yok",
      projelerimiz: [],

      duyurular_isloaded: false,
      duyurular_error: "yok",
      duyurular: [],

      kurumsal_isloaded: false,
      kurumsal_error: "yok",
      kurumsal: [],
    };
    this.verilericek();
  }

  handleChangeTel(e) {
    console.log('I was triggered during render');
    if (e.target.value.match("^[0-9 ()+]*$") != null) {
      this.setState({ tel: e.target.value });
    }
  }

  handleSearch() {
    if (!(this.state.faaliyetlerimiz_isloaded && this.state.projelerimiz_isloaded && this.state.duyurular_isloaded && this.state.duyurular_isloaded)) this.verilericek();

    const { faaliyetlerimiz, projelerimiz, duyurular, kurumsal } = this.state;

    let searchText = this.props.location.state.searchText;

    let faaliyetaramasonuclari = faaliyetlerimiz.filter(item => item.isim.toString().toLowerCase().includes(searchText.toString().toLowerCase()));
    faaliyetaramasonuclari = faaliyetaramasonuclari.concat(faaliyetlerimiz.filter(item => item.anasayfa_aciklamakisa.toString().toLowerCase().includes(searchText.toString().toLowerCase())));
    faaliyetaramasonuclari = faaliyetaramasonuclari.concat(faaliyetlerimiz.filter(item => item.anasayfa_aciklamatam.toString().toLowerCase().includes(searchText.toString().toLowerCase())));
    faaliyetaramasonuclari = faaliyetaramasonuclari.concat(faaliyetlerimiz.filter(item => item.detay_aciklama.toString().toLowerCase().includes(searchText.toString().toLowerCase())));
    faaliyetaramasonuclari = [...new Set(faaliyetaramasonuclari)];


    let projearamasonuclari = projelerimiz.filter(item => item.isim.toString().toLowerCase().includes(searchText.toString().toLowerCase()));
    projearamasonuclari = projearamasonuclari.concat(projelerimiz.filter(item => item.icerik.toString().toLowerCase().includes(searchText.toString().toLowerCase())));
    projearamasonuclari = [...new Set(projearamasonuclari)];


    let duyuruaramasonuclari = duyurular.filter(item => item.baslik.toString().toLowerCase().includes(searchText.toString().toLowerCase()));
    duyuruaramasonuclari = duyuruaramasonuclari.concat(duyurular.filter(item => item.yazar.toString().toLowerCase().includes(searchText.toString().toLowerCase())));
    duyuruaramasonuclari = duyuruaramasonuclari.concat(duyurular.filter(item => item.icerik.toString().toLowerCase().includes(searchText.toString().toLowerCase())));
    duyuruaramasonuclari = [...new Set(duyuruaramasonuclari)];

    let kurumsalaramasonuclari = kurumsal.filter(item => item.baslik.toString().toLowerCase().includes(searchText.toString().toLowerCase()));
    kurumsalaramasonuclari = kurumsalaramasonuclari.concat(kurumsal.filter(item => item.icerik.toString().toLowerCase().includes(searchText.toString().toLowerCase())));
    kurumsalaramasonuclari = [...new Set(kurumsalaramasonuclari)];


    this.setState({
      isLoading: false,
      searchText: searchText,
      faaliyetAramaSonuclari: faaliyetaramasonuclari,
      projeAramaSonuclari: projearamasonuclari,
      duyuruAramaSonuclari: duyuruaramasonuclari,
      kurumsalAramaSonuclari: kurumsalaramasonuclari

    });
  };

  componentDidMount() {
    this.handleSearch();
  }

  componentDidUpdate(prevProps) {
    let prevSearch = prevProps.location.state.searchText;
    let newSearch = this.props.location.state.searchText;
    if (prevSearch !== newSearch) {
      this.handleSearch();
    }
  }



  verilericek = async () => {
    fetch("JSON/faaliyetlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          faaliyetlerimiz_isloaded: true,
          faaliyetlerimiz: result.faaliyetlerimiz
        });
        this.handleSearch();
      },
      (error) => {
        this.setState({
          faaliyetlerimiz_isloaded: true,
          faaliyetlerimiz_error: "Hata var " + error
        });
      }
    )
    fetch("JSON/projelerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          projelerimiz_isloaded: true,
          projelerimiz: result.projelerimiz
        });
        this.handleSearch();
      },
      (error) => {
        this.setState({
          projelerimiz_isloaded: true,
          projelerimiz_error: "Hata var " + error
        });
        this.handleSearch();
      }
    )
    fetch("JSON/duyurular.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          duyurular_isloaded: true,
          duyurular: result.duyurular
        });
      },
      (error) => {
        this.setState({
          duyurular_isloaded: true,
          duyurular_error: "Hata var " + error
        });
      }
    )
    fetch("JSON/kurumsal.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          kurumsal_isloaded: true,
          kurumsal: result.kurumsal
        });
      },
      (error) => {
        this.setState({
          kurumsal_isloaded: true,
          kurumsal_error: "Hata var " + error
        });
      }
    )
  }


  render() {
    let toRender = this.state.isLoading ? (
      <h1 className="text-center m-4">Aranıyor...</h1>
    ) : (
      <>
        <Helmet>
          <title>Arama Sonuçları</title>
        </Helmet>
        <div className="mt-3 text-center">
          <h1>Faaliyet Arama Sonuçları:</h1>
          Aranan: "{this.state.searchText}"<br />
          {this.state.faaliyetAramaSonuclari.length + this.state.projeAramaSonuclari.length + this.state.duyuruAramaSonuclari.length + this.state.kurumsalAramaSonuclari.length > 0 ?
            (<><span>Faaliyetler arasından {this.state.faaliyetAramaSonuclari.length}, projeler arasından {this.state.projeAramaSonuclari.length}, duyurular arasından {this.state.duyuruAramaSonuclari.length}, kurumsal sayfalar arasından {this.state.kurumsalAramaSonuclari.length} adet sonuç bulundu</span><br />

            </>) : (<p>Sonuç bulunamadı</p>)}
        </div>
        <Row>
          {this.state.faaliyetAramaSonuclari.length > 0 ? (
            <>
              {
                this.state.faaliyetAramaSonuclari.map((m, i) => {
                  if (!m.gizli)
                    return <FaaliyetAramaOgesi key={"AramaSonuclari_FaaliyetAramaOgesi" + i} id={m.id} isim={m.isim} detay_aciklama={m.detay_aciklama} resimgenis={m.resimgenis} resimmenu={m.resimmenu} />;
                  else
                    return null;
                }
                )
              }
            </>

          ) : (
            <p></p>
          )}
          {this.state.projeAramaSonuclari.length > 0 ? (
            <>
              {this.state.projeAramaSonuclari.map((m, i) => {
                if (!m.gizli)
                  return <ProjeAramaOgesi key={"AramaSonuclari_FaaliyetAramaOgesi" + i} id={m.id} isim={m.isim} icerik={m.icerik} resim={m.resim} />;
                else
                  return null;
              }
              )
              }

            </>

          ) : (
            <p></p>
          )}
          {this.state.duyuruAramaSonuclari.length > 0 ? (
            <>
              {
                this.state.duyuruAramaSonuclari.map((m, i) => {
                  if (!m.gizli)
                    return <DuyuruAramaOgesi key={"AramaSonuclari_FaaliyetAramaOgesi" + i} id={m.id} baslik={m.baslik} icerik={m.icerik} yazar={m.yazar} tarih={m.tarih}  resimvarmi={m.resimvarmi} resim={m.resim} />;
                  else
                    return null;
                }
                )
              }

            </>

          ) : (
            <p></p>
          )}
          {this.state.kurumsalAramaSonuclari.length > 0 ? (
            <>
              {
                this.state.kurumsalAramaSonuclari.map((m, i) => {
                  if (!m.gizli)
                    return <KurumsalAramaOgesi key={"AramaSonuclari_FaaliyetAramaOgesi" + i} id={m.id} baslik={m.baslik} icerik={m.icerik}  resimvarmi={m.resimvarmi} resim={m.resim} />;
                  else
                    return null;
                }
                )
              }
            </>

          ) : (
            <p></p>
          )}
        </Row>
      </>
    );

    return <Container>{toRender}</Container>;
  }
}

import React from "react";

export default class SayfaBulunamadi extends React.Component {
  render() {
    return(
      <p className="text-center" >
        <img src="/404.png" alt="404" className="pt-5 yuzdeelligenislik"/>
      </p>
    );
  }
}

import React, { Component } from 'react'
import { FloatingButton, Item } from "react-floating-button";
import whatsapp from "../assets/whatsapp.png";

class FloatingActionButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
          formgonder: false,
        };
        this.state = {
          iletisim_isloaded: false,
          iletisim_error: "yok",
          iletisim: [],
        };
        fetch("JSON/iletisim.json").then(res => res.json()).then(
          (result) => {
            this.setState({
              iletisim_isloaded: true,
              iletisim: result
            });
          },
          (error) => {
            this.setState({
              iletisim_isloaded: true,
              iletisim_error: "Hata var " + error
            });
          }
        )
      }

    render() {
        const {iletisim } = this.state;
        return (
            <>
                    <FloatingButton >
                        <Item 
                            imgSrc={whatsapp}
                            onClick={() => {
                                document.location.href = "https://api.whatsapp.com/send?phone="  + (iletisim.whatsapp !== null && iletisim.whatsapp !== "" ? iletisim.whatsapp : "905013559239" )
                            }}
                        />
                    </FloatingButton>
            </>
        );
    }
}

export default FloatingActionButton;

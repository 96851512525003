import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { FaBullhorn } from 'react-icons/fa';


class DuyurularListeOgesi extends React.Component {
  render() {
    return (
      
      <Row className="justify-content-center pb-3 syhlnk" as={Link} to={"/duyurular/" + this.props.id}>
        <Col>
          <h5 ><FaBullhorn />&nbsp;&nbsp;{this.props.baslik} </h5>
        </Col>
      </Row >
    );
  }
}

export default DuyurularListeOgesi;

import { Container, Row, Col, Card,Image } from "react-bootstrap";
import React from "react";
import { Link } from 'react-router-dom'
import Helmet from "react-helmet";
import DigerFaaliyetlermiz from "../API/digerfaaliyetlerimiz";


export default class Projelerimiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sayfaseo_isloaded: false,
      sayfaseo_error: "yok",
      sayfaseo: [],

      projelerimiz_isloaded: false,
      projelerimiz_error: "yok",
      projelerimiz: [],

    };
    fetch(process.env.PUBLIC_URL + "/JSON/sayfaseo.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo: result
        });
      },
      (error) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo_error: "Hata var " + error
        });
      }
    )
    fetch(process.env.PUBLIC_URL + "/JSON/projelerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          projelerimiz_isloaded: true,
          projelerimiz: result.projelerimiz
        });
      },
      (error) => {
        this.setState({
          projelerimiz_isloaded: true,
          projelerimiz_error: "Hata var " + error
        });
      }
    )

    this.aramaref = React.createRef();
  }

  kirp(icerik) {
    var sinir = 200;
    icerik = icerik.replace(/<[^>]+>/g, '');

    if (icerik.length > sinir)
      return icerik.substring(0, sinir) + "... DEVAMI İÇİN TIKLAYIN";
    else
      return icerik;
  }
  render() {
    const { projelerimiz_isloaded, projelerimiz_error, projelerimiz, sayfaseo } = this.state;


    return (
      <>
        <Helmet>
          <title>{sayfaseo.projelerimiz_metatitle}</title>
          <meta name="description" content={sayfaseo.projelerimiz_metadescription} />
          <meta name="keywords" content={sayfaseo.projelerimiz_metakeywords} />

          <meta property="og:title" content={sayfaseo.projelerimiz_metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="%PUBLIC_URL%/egitmenler" />
          <meta property="og:description" content={sayfaseo.projelerimiz_metadescription} />
          <meta property="og:site_name" content="Minik Dokunuşlar Derneği" />
          <meta name="twitter:image:alt" content="Minik Dokunuşlar Derneği" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <section id="projelerimizsection" className="bg">
          <Container>
            <Row className="justify-content-center">
              <Col sm={10} className="text-center">
                <h4>Projelerimiz</h4>
              </Col>
            </Row>

            {/* Eğitimlerimizi Listele */}
            {projelerimiz_isloaded ? (<>

              <Row >
                <Col xs="12" md="8">
                  {projelerimiz.map((m, i) => {
                    if (!m.gizli)
                      return (
                        <Card key={"projelerimiz_key_" + m.id} className="m-1 p-2">
                          <Link to={"/projelerimiz/" + m.id} className="syhlnk">
                            <Row>
                              <Col sm="12" md="4">
                                <Image className="rounded" onError={this.resimyok} src={m.resim} alt={m.isim} width={"100%"} />
                              </Col>
                              <Col>
                                <span className="syhlnk">
                                  <div className="m-1"><h5><b>{m.isim}</b></h5></div>
                                  <div className="m-1 mt-3">{this.kirp(m.icerik)}</div>
                                </span>
                              </Col>
                            </Row>
                          </Link>

                        </Card>

                      );
                    else
                      return "";
                  })}
                </Col>
                <Col xs={12} md={4} className="faaliyetlerimizblok">
                  <DigerFaaliyetlermiz />
                </Col>
              </Row>
            </>) : (<>

              {projelerimiz_error === "yok" ? (<>
                <div>Projelerimiz modülü yükleniyor, lütfen bekleyin.</div>
              </>) : (<div className="hata">Projelerimiz Modülü yüklenemedi...</div>)}</>)}
          </Container>

        </section>
      </>
    );
  }
}

import React from "react";
import { Col, Button, Card, Row } from "react-bootstrap";
import "video-react/dist/video-react.css"; // import css
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'


class KurumsalAramaOgesi extends React.Component {
  resimyok(ev) {
    ev.target.src = '/images/makaleyok.png'
  }

  render() {
    return (
      <Col sm={6}>
        <Card className="mt-2 ml-2">
          <Card.Header>{this.props.baslik}</Card.Header>
          <Card.Body>

            {this.props.resimvarmi ?
              <Row><Col lg="3"><img src={this.props.resim} style={{ width: 120 }} alt={this.props.baslik} /> </Col>
                <Col lg="9">{parse(this.props.icerik.substring(0, 200))}</Col></Row> :
              <Row>
                <Col lg="12">{parse(this.props.icerik.substring(0, 200))}</Col></Row>}

            <Button as={Link} to={"kurumsal/" + this.props.id} variant="danger" className="sagayasli">Kurumsal Sayfa Detayı</Button>{' '}
          </Card.Body>
        </Card>
      </Col>

    );
  }
}

export default KurumsalAramaOgesi;

import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FaInstagram, FaTwitter, FaYoutube, FaLock, FaFacebook, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from "styled-components";

const SLink = styled(Link)`
  color: black;
  text-decoration: none;
  margin: 0.1rem;
  position: relative;
`;

class Enalt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,

            kurumsal_isloaded: false,
            kurumsal_error: "yok",
            kurumsal: [],


            iletisim_isloaded: false,
            iletisim_error: "yok",
            iletisim: []
        };
        fetch("JSON/kurumsal.json").then(res => res.json()).then(
            (result) => {
                this.setState({
                    kurumsal_isloaded: true,
                    kurumsal: result.kurumsal
                });
            },
            (error) => {
                this.setState({
                    kurumsal_isloaded: true,
                    kurumsal_error: "Hata var " + error
                });
            }
        )
        fetch(process.env.PUBLIC_URL + "/JSON/iletisim.json").then(res => res.json()).then(
            (result) => {
                this.setState({
                    iletisim_isloaded: true,
                    iletisim: result
                });
            },
            (error) => {
                this.setState({
                    iletisim_isloaded: true,
                    iletisim_error: "Hata var " + error
                });
            }
        )
    }
    render() {
        const { iletisim, kurumsal_isloaded, kurumsal_error, kurumsal, } = this.state;

        return (
            <>
                <Container>
                    <div className="kirmizicizgidiv" />
                    <footer className="pt-2 my-md-2 pt-md-4 text-center">
                        <Row>
                            <Col xs={12} md={4}>
                                <h5>Keşfet</h5>
                                <ul className="list-unstyled text-small">
                                    <li><SLink to="/faaliyetlerimiz">Faatliyetlerimiz</SLink></li>
                                    <li><SLink to="/projelerimiz">Projelerimiz</SLink></li>
                                    <li><SLink to="/duyurular">Duyurlar</SLink></li>
                                </ul>
                            </Col>
                            <Col xs={12} md={4}>
                                <h5>Kurumsal</h5>
                                <ul className="list-unstyled text-small">
                                    {kurumsal_isloaded && kurumsal_error === "yok" ?
                                        (
                                            <>
                                                {kurumsal.map((m, i) => {
                                                    if (m.tur === "sayfa") return (<li key={"enalt_kurumsal" + i}><SLink eventkey={i + 130} to={"/kurumsal/" + m.id}>{m.baslik}</SLink></li>);
                                                    if (m.tur === "baglanti") return (<li key={"enalt_kurumsal" + i}><a href={m.id} className="syhlnk">{m.baslik}</a></li>);
                                                    return "";
                                                })}
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                {kurumsal_error === "yok" ? (<div>Yükleniyor</div>) : (<div>Hata:{kurumsal_error}</div>)}
                                            </>
                                        )
                                    }
                                </ul>
                            </Col>

                            <Col xs={12} md={4} >
                                <h5>Sosyal Medya</h5>
                                <ul className="list-unstyled text-small">

                                 {(iletisim.facebook !== undefined && iletisim.facebook !== null && iletisim.facebook !== "") ?<li><a className="syhlnk" href={iletisim.facebook} target={"_blank"} rel="noopener noreferrer"><FaFacebook /> facebook</a></li>: ""}
                                 {(iletisim.instagram !== undefined && iletisim.instagram !== null && iletisim.instagram !== "") ?<li><a className="syhlnk" href={iletisim.instagram} target={"_blank"} rel="noopener noreferrer"><FaInstagram /> Instagram</a></li>: ""}
                                 {(iletisim.twitter !== undefined && iletisim.twitter !== null && iletisim.twitter !== "") ?<li><a className="syhlnk" href={iletisim.twitter} target={"_blank"} rel="noopener noreferrer"><FaTwitter /> Twitter</a></li>: ""}
                                 {(iletisim.youtube !== undefined && iletisim.youtube !== null && iletisim.youtube !== "") ?<li><a className="syhlnk" href={iletisim.youtube} target={"_blank"} rel="noopener noreferrer"><FaYoutube /> Youtube</a></li>: ""}
                                 {(iletisim.linkedin !== undefined && iletisim.linkedin !== null && iletisim.linkedin !== "") ?<li><a className="syhlnk" href={iletisim.linkedin} target={"_blank"} rel="noopener noreferrer"><FaLinkedin /> LinkedIn</a></li>: ""}
                                 {(iletisim.tiktok !== undefined && iletisim.tiktok !== null && iletisim.tiktok !== "") ?<li><a className="syhlnk" href={iletisim.tiktok} target={"_blank"} rel="noopener noreferrer"><FaTiktok /> Tiktok</a></li>: ""}
                                </ul>
                            </Col>
                        </Row>
                    </footer>
                    <div className="kirmizicizgidiv" />

                    <Row className="text-center">
                        <Col xs={12} className="mb-2" >
                            <img src="/logo.png" alt="hover" className="altlogoimg" /> <span className="altlogo">Minik Dokunuşlar Derneği</span>
                        </Col>
                        <Col xs={12} className="mb-4">
                            © 2023 Minik Dokunuşlar Derneği<br /><FaLock /> Secured with SSL
                        </Col>
                    </Row>
                </Container>
            </>

        );
    }
}

export default Enalt;

import { Container, Row, Col } from "react-bootstrap";
import parse from 'html-react-parser'
import Helmet from "react-helmet";
import React from 'react';
import DigerFaaliyetlerimiz from "../API/digerfaaliyetlerimiz";
import VPlayers from "../API/players";

export default class ProjeDetay extends React.Component {

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.metatitle}</title>
          <meta name="description" content={this.props.metadescription} />
          <meta name="keywords" content={this.props.metakeywords} />

          <meta property="og:title" content={this.props.metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={this.props.resim} />
          <meta property="og:url" content={"%PUBLIC_URL%/" + this.props.id} />
          <meta property="og:description" content={this.props.metadescription} />
          <meta property="og:site_name" content="Minik Dokunuşlar Derneği" />
          <meta name="twitter:image:alt" content="Minik Dokunuşlar Derneği" />
          <meta name="twitter:card" content="summary_large_image" />

        </Helmet>

        <Container className="container-xl pt-3">
          <Container>
            <Row>
              <Col xs={12} md={8}>
                <Row>
                  <Col xs={12} md={8}> <img src={"/" + this.props.resim} className="rounded yuzdeyuzgenislik" alt={this.props.isim} /></Col>
                </Row>
                <h2 className="pb-2 pt-2">{this.props.isim}</h2>
                <div >{parse(this.props.icerik)}</div>
                <Row>
                  <VPlayers album={this.props.album} />
                </Row>

              </Col>
              <Col xs={12} md={4} className="faaliyetlerimizblok">
                <DigerFaaliyetlerimiz />
              </Col>
            </Row>

          </Container>
        </Container>
      </>
    );
  }
}

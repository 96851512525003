import React from "react";
import { Link } from 'react-router-dom'

class DigerFaaliyetlerimiz extends React.Component {
    baglandimi = false;

    constructor(props) {
        super(props);
        this.state = {
            faaliyetlerimiz_isloaded: false,
            faaliyetlerimiz_error: "yok",
            faaliyetlerimiz: [],
            sonOnfaaliyet: [],

            projelerimiz_isloaded: false,
            projelerimiz_error: "yok",
            projelerimiz: [],
            sonOnproje: []
        };

    }
    componentDidMount() {
        this.baglandimi = true;

        this.verilericek();
    }
    componentWillUnmount() {
        this.baglandimi = false;
    }
    verilericek() {
        fetch(process.env.PUBLIC_URL + "/JSON/faaliyetlerimiz.json").then(res => res.json()).then(
            (result) => {
                if (this.baglandimi) {
                    this.setState({
                        faaliyetlerimiz_isloaded: true,
                        faaliyetlerimiz: result.faaliyetlerimiz,
                        sonOnfaaliyet: result.faaliyetlerimiz.slice(result.faaliyetlerimiz.length - 10 >= 0 ? result.faaliyetlerimiz.length - 10 : 0)
                    });

                }

            },
            (error) => {
                if (this.baglandimi) {
                    this.setState({
                        faaliyetlerimiz_isloaded: true,
                        faaliyetlerimiz_error: "Hata var " + error
                    });
                }
            }
        );
        fetch(process.env.PUBLIC_URL + "/JSON/projelerimiz.json").then(res => res.json()).then(
            (result) => {
                if (this.baglandimi) {
                    this.setState({
                        projelerimiz_isloaded: true,
                        projelerimiz: result.projelerimiz,
                        sonOnproje: result.projelerimiz.slice(result.projelerimiz.length - 10 >= 0 ? result.projelerimiz.length - 10 : 0)
                    });

                }

            },
            (error) => {
                if (this.baglandimi) {
                    this.setState({
                        projelerimiz_isloaded: true,
                        projelerimiz_error: "Hata var " + error
                    });
                }
            }
        );
    }
    render() {
        const { faaliyetlerimiz_isloaded, faaliyetlerimiz_error, sonOnfaaliyet, projelerimiz_isloaded, projelerimiz_error, sonOnproje } = this.state;

        return (<>
            {sonOnfaaliyet.length > 0 ?

                <div class="list-group">
                    <Link to="/faaliyetlerimiz" className="list-group-item list-group-item-action" ><b>Faaliyetlerimiz</b></Link>
                    {faaliyetlerimiz_isloaded && faaliyetlerimiz_error === "yok" ?
                        (
                            <>
                                {sonOnfaaliyet.map((m, i) => {
                                    if (!m.gizli) return (<Link className="list-group-item list-group-item-action" eventkey={i + 130} to={"/faaliyetlerimiz/" + m.id} key={"enalt_faaliyetlerimiz" + i}>• {m.isim}</Link>);
                                    return "";
                                })}
                            </>
                        )
                        :
                        (
                            <>
                                {faaliyetlerimiz_error === "yok" ? (<div>Yükleniyor</div>) : (<div>Hata:{faaliyetlerimiz_error}</div>)}
                            </>
                        )
                    }
                </div> : <></>}

            {sonOnproje.length > 0 ?
                <div class="list-group mt-2">
                    <Link to="/projelerimiz" className="list-group-item list-group-item-action" ><b>Projelerimiz</b></Link>

                    {projelerimiz_isloaded && projelerimiz_error === "yok" ?
                        (
                            <>
                                {sonOnproje.map((m, i) => {
                                    if (!m.gizli) return (<Link className="list-group-item list-group-item-action" key={"enalt_projelerimiz" + i} eventkey={i + 130} to={"/projelerimiz/" + m.id}>• {m.isim}</Link>);
                                    return "";
                                })}
                            </>
                        )
                        :
                        (
                            <>
                                {projelerimiz_error === "yok" ? (<div>Yükleniyor</div>) : (<div>Hata:{projelerimiz_error}</div>)}
                            </>
                        )
                    }
                </div> : <></>}</>
        );
    }
}

export default DigerFaaliyetlerimiz;

import React from "react";
import { Col, NavDropdown, Row } from "react-bootstrap";
import "video-react/dist/video-react.css"; // import css
import { Link } from 'react-router-dom'


class FaaliyetlerimizMenuOgesi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      faaliyetlerimiz_isloaded: false,
      faaliyetlerimiz_error: "yok",
      faaliyetlerimiz: [],
    };
    fetch(process.env.PUBLIC_URL + "/JSON/faaliyetlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          faaliyetlerimiz_isloaded: true,
          faaliyetlerimiz: result.faaliyetlerimiz
        });
      },
      (error) => {
        this.setState({
          faaliyetlerimiz_isloaded: true,
          faaliyetlerimiz_error: "Hata var " + error
        });
      }
    )
  }
  /*
  verilericek() {
    fetch("JSON/faaliyetlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          faaliyetlerimiz_isloaded: true,
          faaliyetlerimiz: result.faaliyetlerimiz
        });
      },
      (error) => {
        this.setState({
          faaliyetlerimiz_isloaded: true,
          faaliyetlerimiz_error: "Hata var " + error
        });
      }
    )
  }

  componentDidMount() {
    this.verilericek();
    this.timer = setInterval(() => this.verilericek(), 5000);
    this.setState({ height: window.innerHeight });
  }
  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }
*/

  render() {
    const { faaliyetlerimiz_isloaded, faaliyetlerimiz_error, faaliyetlerimiz, } = this.state;

    return (
      <>
        {faaliyetlerimiz_isloaded && faaliyetlerimiz_error === "yok" ?
          (
            <>
              {faaliyetlerimiz.map((m, i) => {
                if (!m.gizli) return (
                  <NavDropdown.Item key={"faaliyetmenuogesi_faaliyet" + i} eventkey={i + 30} as={Link} to={"/faaliyetlerimiz/" + m.id} href="#">
                    <Row>
                      <Col>                        
                      <div className="rounded">
                          <img src={"/" + m.resimmenu} className="rounded menuresmi" alt="Eğitmen Resmi" />&nbsp;&nbsp;<span>{m.isim}</span>
                        </div>
                      </Col>
                    </Row>
                  </NavDropdown.Item>);
                else
                  return "";
              })}
            </>
          )
          :
          (
            <>
              {faaliyetlerimiz_error === "yok" ? (<div>Yükleniyor</div>) : (<div>Hata:{faaliyetlerimiz_error}</div>)}
            </>
          )
        }

      </>
    );
  }
}

export default FaaliyetlerimizMenuOgesi;

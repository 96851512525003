import React from "react";
import { Row, Col } from "react-bootstrap";
import "video-react/dist/video-react.css"; // import css
import { Link } from 'react-router-dom'

class FaaliyetlerimizListeOgesi extends React.Component {
  render() {

    return (

      <Row className="justify-content-center pb-3 syhlnk" as={Link} to={"/faaliyetlerimiz/" + this.props.id}>
        <Col lg={4}>            
        <div className="relative">
          <img src={this.props.resimgenis} className="img-fluid w-100" alt="yok" />
        </div>
        </Col>
        <Col lg={8} >
          <h5 >{this.props.isim} </h5>
          <h6>
            <p className="kisa">{this.props.anasayfa_aciklamakisa}...</p>
          </h6>
        </Col>

      </Row>
    );
  }
}
export default FaaliyetlerimizListeOgesi;

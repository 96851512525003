import { Container, Row, Col, Card,Image } from "react-bootstrap";
import React from "react";
import { Link } from 'react-router-dom'
import Helmet from "react-helmet";
import DigerFaaliyetlerimiz from "../API/digerfaaliyetlerimiz";

export default class Duyurular extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sayfaseo_isloaded: false,
      sayfaseo_error: "yok",
      sayfaseo: [],

      duyurular_isloaded: false,
      duyurular_error: "yok",
      duyurular: [],
    };

    fetch(process.env.PUBLIC_URL + "/JSON/sayfaseo.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo: result
        });
      },
      (error) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo_error: "Hata var " + error
        });
      }
    )
    fetch(process.env.PUBLIC_URL + "/JSON/duyurular.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          duyurular_isloaded: true,
          duyurular: result.duyurular
        });
      },
      (error) => {
        this.setState({
          duyurular_isloaded: true,
          duyurular_error: "Hata var " + error
        });
      }
    )
    this.aramaref = React.createRef();
  }

  kirp(icerik) {
    var sinir = 200;
    icerik = icerik.replace(/<[^>]+>/g, '');

    if (icerik.length > sinir)
      return icerik.substring(0, sinir) + "... DEVAMI İÇİN TIKLAYIN";
    else
      return icerik;
  }

  resimyok(ev) {
    ev.target.src = 'images/makaleyok.png'
  }

  render() {
    const { duyurular_isloaded, duyurular_error, duyurular, sayfaseo } = this.state;


    return (
      <>
        <Helmet>
          <title>{sayfaseo.duyurularimiz_metatitle}</title>
          <meta name="description" content={sayfaseo.duyurularimiz_metadescription} />
          <meta name="keywords" content={sayfaseo.duyurularimiz_metakeywords} />

          <meta property="og:title" content={sayfaseo.duyurularimiz_metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="%PUBLIC_URL%/blog" />
          <meta property="og:description" content={sayfaseo.duyurularimiz_metadescription} />
          <meta property="og:site_name" content="Minik Dokunuşlar Derneği" />
          <meta name="twitter:image:alt" content="Minik Dokunuşlar Derneği" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <section id="duyurularsection" className="bg">
          <Container>
            <Row className="justify-content-center">
              <Col sm={10} className="text-center">
                <h4>Duyurulular</h4>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                {/* Eğitimlerimizi Listele */}
                {duyurular_isloaded ? (<>

                  {duyurular.map((m, i) => {
                    if (!m.gizli) {
                      if (m.resimvarmi) {
                        return (
                          <Card className="m-1 p-2" key={"duyuru_id_" + i}>
                            <Link to={"/duyurular/" + m.id} className="syhlnk" >
                              <Row>
                                <Col sm="12" md="4">
                                  <Image className="rounded" onError={this.resimyok} src={m.resim} alt={m.baslik} width={"100%"} />
                                </Col>
                                <Col>
                                  <span className="syhlnk">
                                    <div className="m-1"><h5><b>{m.baslik}</b></h5></div>
                                    <div className="m-1"><i>{m.yazar} - {m.tarih}</i></div>
                                    <div className="m-1 mt-3">{this.kirp(m.icerik)}</div>
                                  </span>
                                </Col>
                              </Row>
                            </Link>
                          </Card>

                        );
                      }
                      else {
                        return (
                          <Row className="card">
                            <Col xs="12" key={"duyurular_Liste" + i} >
                              <Link to={"/duyurular/" + m.id} className="syhlnk">
                                <div className="blogdiv" >
                                  <div>
                                    <img className="rounded" src='images/makaleyok.png' alt='images/makaleyok.png' width="80%" />
                                  </div>
                                  <div>
                                    {m.baslik}<br />
                                    {m.yazar}
                                  </div>
                                </div>
                              </Link>
                            </Col>
                          </Row >
                        );
                      }
                    }
                    else
                      return "";
                  }
                  )}


                </>) : (<>

                  {duyurular_error === "yok" ? (<>
                    <div>Eğitimlerimiz modülü yükleniyor, lütfen bekleyin.</div>
                  </>) : (<div className="hata">SSS Modülü yüklenemedi...</div>)}</>)}
              </Col>
              <Col xs={12} md={4} className="faaliyetlerimizblok">
                <DigerFaaliyetlerimiz />
              </Col>
            </Row>
          </Container>

        </section>
      </>
    );
  }
}

import React from "react";
import { Carousel, Row, Col, Button, Container, Card, Image } from "react-bootstrap";
import { Link } from 'react-router-dom'
import FaaliyetlerimizListeOgesi from "../API/faaliyetlerimizlisteogesi";
import DuyurularListeOgesi from "../API/duyurularlisteogesi";
import Helmet from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class AnaSayfa extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tel: "",
      height: "100vh",

      sayfaseo_isloaded: false,
      sayfaseo_error: "yok",
      sayfaseo: [],

      faaliyetlerimiz_isloaded: false,
      faaliyetlerimiz_error: "yok",
      faaliyetlerimiz: [],
      sonUcfaaliyet: [],

      duyurular_isloaded: false,
      duyurular_error: "yok",
      duyurular: [],
      sonUcduyuru: [],

      karusel_isloaded: false,
      karusel_error: "yok",
      karusel: [],

    };
    this.verilericek();

  }
  handleResize = e => {
    var x = 100;
    if (window.innerWidth > 991) x = 55;
    const height = window.innerHeight - x;
    this.setState(prevState => {
      return {
        height
      };
    });
  };
  verilericek() {
    fetch(process.env.PUBLIC_URL + "/JSON/sayfaseo.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo: result
        });
      },
      (error) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo_error: "Hata var " + error
        });
      }
    )

    fetch(process.env.PUBLIC_URL + "/JSON/faaliyetlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          faaliyetlerimiz_isloaded: true,
          faaliyetlerimiz: result.faaliyetlerimiz,
          sonUcfaaliyet: result.faaliyetlerimiz.slice(0, 3),
        });
      },
      (error) => {
        this.setState({
          faaliyetlerimiz_isloaded: true,
          faaliyetlerimiz_error: "Hata var " + error
        });
      }
    )

    fetch(process.env.PUBLIC_URL + "/JSON/karusel.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          karusel_isloaded: true,
          karusel: result.karusel
        });
      },
      (error) => {
        this.setState({
          karusel_isloaded: true,
          karusel_error: "Hata var " + error
        });
      }
    )
    fetch(process.env.PUBLIC_URL + "/JSON/duyurular.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          duyurular_isloaded: true,
          duyurular: result.duyurular,
          sonUcduyuru: result.duyurular.slice(0, 3),
        });
      },
      (error) => {
        this.setState({
          duyurular_isloaded: true,
          duyurular_error: "Hata var " + error
        });
      }
    )

  }



  componentDidMount() {
    this.timer = setInterval(() => this.verilericek(), 5000);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);

    clearInterval(this.timer);
    this.timer = null;
  }


  render() {
    const { height, faaliyetlerimiz_isloaded, faaliyetlerimiz_error, sonUcfaaliyet, karusel_isloaded, karusel_error, karusel, duyurular_isloaded, duyurular_error, sonUcduyuru, sayfaseo } = this.state;
    return (
      <>
        {/* anasayfa carousel */}
        <Helmet>
          <title>{sayfaseo.anasayfa_metatitle}</title>
          <meta name="description" content={sayfaseo.anasayfa_metadescription} />
          <meta name="keywords" content={sayfaseo.anasayfa_metakeywords} />
          <meta property="og:title" content={sayfaseo.anasayfa_metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="images/biz-kimiz.png" />
          <meta property="og:url" content="index.html" />
          <meta property="og:description" content={sayfaseo.anasayfa_metadescription} />
          <meta property="og:site_name" content="Minik Dokunuşlar Derneği" />
          <meta name="twitter:image:alt" content="Minik Dokunuşlar Derneği" />
          <meta name="twitter:card" content="summary_large_image" />

        </Helmet>
        <section id="carousel">
          {karusel_isloaded ?
            <Carousel>
              {karusel.map((item, i) => {
                if (!item.gizli) return (
                  <Carousel.Item key={"AnaSayfa_Carousel" + i}>
                    <div className="resimyukseklikdivi" style={{ height: height }}>
                      <div className="resimcerceve"></div>
                      <img className="d-none d-sm-block w-100 resim" src={item.resimgenis} alt="Geniş Resim" />
                      <img className="d-block d-sm-none w-100 resim" src={item.resimdik} alt="Dar Resim" />
                    </div>
                    <Carousel.Caption className="carousel-caption  d-md-block">
                      <Row className="d-none d-md-block">
                        <Col xs={12}>
                          <Image src="logo800.png" className="pb-4" width={"35%"} />
                          <h1 className="golgeli pb-4">{item.baslik}</h1>
                          <h5 className="golgeli pb-4">{item.anasayfa_aciklama.substring(0, 300)}</h5>
                        </Col>
                      </Row>
                      <Row className="d-block d-md-none p-2">
                        <Image src="logo800.png"  className="pb-4" width={"35%"} />
                        <h5 className="golgeli pb-4">{item.baslik}</h5>
                        <p className="golgeli pb-4">{item.anasayfa_aciklama}</p>
                      </Row>

                      <Row className="d-none d-md-block">
                        <Col xs={12} className="pb-4">
                          {item.baglanti.includes("http:") ?
                            <a className="btn btn-danger" href={item.baglanti}>{item.buton}</a>
                            :
                            <Button as={Link} to={item.baglanti} variant="danger" >{item.buton}</Button>}
                        </Col>
                      </Row>
                      <Row className="d-block d-md-none pb-4">
                        {item.baglanti.includes("http:") ?
                          <a className="btn btn-danger" href={item.baglanti}>{item.buton}</a>
                          :
                          <Button as={Link} to={item.baglanti} variant="danger" >{item.buton}</Button>}
                      </Row>
                    </Carousel.Caption>
                  </Carousel.Item>);
                else
                  return "";
              })
              }
            </Carousel> :
            <div>{karusel_error}</div>}
        </section>

        {/* ana sayfa faaliyetlerimiz ve duyurular */}
        <section id="faaliyetlerimizlistesi" className="bg mt-4">
          <Container>
            <Row>
              <Col md={8} xs={12}>
                <Card className="mb-2">
                  <Card.Header>Son Faaliyetlerimiz
                  </Card.Header>
                  <Card.Body>
                    {/* Faaliyetlerimizi Listele */}
                    {faaliyetlerimiz_isloaded ? (<>

                      {sonUcfaaliyet.map((m, i) => {
                        if (!m.gizli) return (
                          <Row key={"AnaSayfa_Egitim_Listele" + i}>
                            <FaaliyetlerimizListeOgesi id={m.id} isim={m.isim} anasayfa_aciklamakisa={m.anasayfa_aciklamakisa} anasayfa_aciklamatam={m.anasayfa_aciklamatam} detay_aciklama={m.detay_aciklama} resimgenis={m.resimgenis} resimmenu={m.resimmenu} />
                          </Row>
                        ); else return "";
                      }
                      )}

                    </>) : (<>
                      {faaliyetlerimiz_error === "yok" ? (<>
                        <div>Eğitimler yükleniyor, lütfen bekleyin.</div>
                      </>) : (<div className="hata">SSS Modülü yüklenemedi...</div>)}</>)}
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} xs={12}>
                <Card className="mb-2">
                  <Card.Header>Son Duyurularımız
                  </Card.Header>
                  <Card.Body>
                    {/* Duyurularımız Listele */}
                    {duyurular_isloaded ? (<>

                      {sonUcduyuru.map((m, i) => {
                        if (!m.gizli) return (
                          <Row key={"AnaSayfa_Egitim_Listele" + i}>
                            <DuyurularListeOgesi id={m.id} baslik={m.baslik} yazar={m.yazar} tarih={m.tarih} icerik={m.icerik} gizli={m.gizli} resimvarmi={m.resimvarmi} resim={m.resim} />
                          </Row>
                        ); else return "";
                      }
                      )}

                    </>) : (<>
                      {duyurular_error === "yok" ? (<>
                        <div>Duyurular yükleniyor, lütfen bekleyin.</div>
                      </>) : (<div className="hata">Duyurular Modülü yüklenemedi...</div>)}</>)}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

        </section>

      </>
    );
  }
}

import React from "react";
import { Col, Button, Card, Row } from "react-bootstrap";
import "video-react/dist/video-react.css"; // import css
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'


class FaaliyetAramaOgesi extends React.Component {
  render() {
    return (
        <Col sm={6}>
          <Card className="mt-2 ml-2">
            <Card.Header>{this.props.isim}</Card.Header>
            <Card.Body>
              <Row>
                <Col lg="3"><img src={this.props.resimgenis} style={{width:120}} alt={this.props.isim} /> </Col>
                <Col lg="9">{parse(this.props.detay_aciklama.substring(0, 200))}</Col></Row>
                <Button as={Link} to={"faaliyetlerimiz/" + this.props.id} variant="danger" className="sagayasli">Faaliyet Detayı</Button>{' '}
            </Card.Body>
          </Card>
        </Col>

    );
  }
}

export default FaaliyetAramaOgesi;

import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import parse from 'html-react-parser'
import Helmet from "react-helmet";

export default class Kurumsal extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.metatitle}</title>
          <meta name="description" content={this.props.metadescription} />
          <meta name="keywords" content={this.props.metakeywords} />

          <meta property="og:title" content={this.props.metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={this.props.resimgenis} />
          <meta property="og:url" content={"%PUBLIC_URL%/" + this.props.id} />
          <meta property="og:description" content={this.props.metadescription} />
          <meta property="og:site_name" content="Minik Dokunuşlar Derneği" />
          <meta name="twitter:image:alt" content="Minik Dokunuşlar Derneği" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Container className="container-xl pt-3">

          <Row className="pb-3 justify-content-center">
            <Col sm={10} className="pb-3">
              <h1>{this.props.baslik}</h1>
            </Col>
            {!this.props.resimvarmi ? (<>
              <Col sm={10} className="pb-3">
                <div>{parse(this.props.icerik)}</div>
              </Col>
            </>) : (<>
              <Col sm={10} lg={4} className="pb-3">
                <img src={"/"+ this.props.resim} className="rounded kurumsalresim" alt={this.props.isim} />
              </Col>
              <Col sm={10} lg={6} className="pb-3">
                <div>{parse(this.props.icerik)}</div>
              </Col>
            </>)}

          </Row>
        </Container>
      </>
    );
  }
}

import React from "react";
import { Button, Modal, Image, Row, Col } from "react-bootstrap";
import { FaYoutube } from "react-icons/fa";



class VPlayers extends React.Component {
    state = { modalgoster: false, index: 0 };



    showModal(s) {
        this.setState({ modalgoster: true, index: s })
    }
    hideModal = () => {
        this.setState({ modalgoster: false, index: 0 })
    }
    Onceki = () => {
        this.setState({ modalgoster: true, index: this.state.index > 0 ? this.state.index - 1 : this.props.album.length - 1  })
    }
    Sonraki = () => {
        this.setState({ modalgoster: true, index: this.state.index < this.props.album.length - 1 ? this.state.index + 1 : 0 })
    }
    componentDidMount() {
        document.addEventListener('keydown', this.detectkeyDown, true);
    }

    detectkeyDown = (e) => {
        if (e.keyCode === 37) {
            this.Onceki();
        }
        if (e.keyCode === 39) {
            this.Sonraki();
        }
    }
    render() {
        const { modalgoster } = this.state;

        return (
            <>
                {this.props.album.length > 0
                    ?
                    <><div><h5>Fotoğraflar</h5><hr /></div>
                        <Row>
                            {this.props.album.map((m, i) => {
                                return (

                                    <Col xl={2} lg={3} sm={4} xs={12} key={"onizleme_" + i}>
                                        <div style={{ "position": "relative" }} onMouseDown={(s) => this.showModal(i)} >
                                            {m.tur === "video" ?
                                                <div>
                                                    <div style={{ "position": "absolute", "left": "calc(+50% - 30px)", "top": "calc(+50% - 30px)" }}>
                                                        <FaYoutube color="red" size={60} />
                                                    </div>
                                                    <video className="img-thumbnail" height={100} width={200} style={{ "width": "200px" , "height" : "100px" }} >
                                                        <source src={m.dosya} type="video/mp4" />
                                                    </video>
                                                </div> : <div height={100} width={200} className="img-thumbnail text-center" ><Image style={{ "max-height": "100px" }} src={m.dosya} fluid /></div>}

                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>

                        <Modal show={modalgoster} onHide={this.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Body style={{ "max-height": "calc(100vh - 200px)" }}>
                                <Row>
                                    <Col xs={2}>
                                        <Button className="float-start" onClick={this.Onceki}>Önceki</Button>
                                    </Col>
                                    <Col xs={8} className="text-center">
                                        ({this.state.index + 1}/{this.props.album.length})
                                        {(this.props.album[this.state.index]).tur === "video" ? "Video" : "Resim"} <br /> <span style={{ fontSize: 12, fontWeight: "bold" }}>Klavye sağ sol tuşlarına basarak gezinebilirsiniz.</span>
                                    </Col>
                                    <Col xs={2}>
                                        <Button className="float-end" onClick={this.Sonraki}>Sonraki</Button>
                                    </Col>
                                </Row>

                                {(this.props.album[this.state.index]).tur === "video" ? <video key={"pv" + this.state.index} style={{ "max-height": "calc(100vh - 260px)", "height": "100%", "width": "100%" }} controls autoPlay={true} ><source src={(this.props.album[this.state.index]).dosya} type="video/mp4" /></video>
                                    : <div className="text-center" ><Image src={(this.props.album[this.state.index]).dosya} style={{ "max-height": "calc(100vh - 260px)" }} fluid /></div>}
                            </Modal.Body>
                        </Modal>
                    </> : <></>}

            </>
        )
    }
}

export default VPlayers;

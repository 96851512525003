import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Ana from "./Ana";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BrowserRouter>
      <Ana />
    </BrowserRouter>
  );
}


const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

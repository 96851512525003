import React from "react";
import { NavDropdown } from "react-bootstrap";
import "video-react/dist/video-react.css"; // import css
import { Link } from 'react-router-dom'


class KurumsalMenuOgesi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,

            kurumsal_isloaded: false,
            kurumsal_error: "yok",
            kurumsal: [],
        };
        fetch(process.env.PUBLIC_URL + "/JSON/kurumsal.json").then(res => res.json()).then(
            (result) => {
                this.setState({
                    kurumsal_isloaded: true,
                    kurumsal: result.kurumsal
                });
            },
            (error) => {
                this.setState({
                    kurumsal_isloaded: true,
                    kurumsal_error: "Hata var " + error
                });
            }
        )
    }

    render() {
        const { kurumsal_isloaded, kurumsal_error, kurumsal, } = this.state;

        return (
            <>

                {kurumsal_isloaded && kurumsal_error === "yok" ?
                    (
                        <>
                            {kurumsal.map((m, i) => {
                                if(!m.gizli && m.tur==="sayfa") return (<NavDropdown.Item key={"kurumsalmenuogesi" + i} eventkey={i + 30} as={Link} to={"/kurumsal/"+m.id} href="#">{m.baslik}</NavDropdown.Item>);
                                if(!m.gizli && m.tur==="ayrac") return (<NavDropdown.Divider key={"kurumsalmenuogesi" + i} />);
                                if(!m.gizli && m.tur === "baglanti") return  (<NavDropdown.Item key={"kurumsalmenuogesi" + i} href={m.id} >{m.baslik}</NavDropdown.Item>);
                                return (<></>);
                            })}
                        </>
                    )
                    :
                    (
                        <>
                            {kurumsal_error === "yok" ? (<div>Yükleniyor</div>) : (<div>Hata:{kurumsal_error}</div>)}
                        </>
                    )
                }

            </>
        );
    }
}

export default KurumsalMenuOgesi;
